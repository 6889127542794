const _ = {
  find: require('lodash/find'),
  pick: require('lodash/pick'),
};
import * as React from 'react';
import classnames from 'classnames';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { MutationFn } from 'react-apollo';
import Helmet from 'react-helmet';
import { DataProxy } from 'apollo-cache';
import * as qs from 'qs';

import { routes } from 'inkp-routes/public';
import Input from 'inkp-components/dist/Components/Input';
import Button from 'inkp-components/dist/Components/Button';
import { validateSignInParams } from 'inkp-user-sdk/user';
import { Mutation as UserMutationResults } from 'inkp-user-sdk/types.g';
import { StateContainer, Subscribe } from 'infra-frontend/helpers/apollo';

import { FieldError } from '../../interfaces';
import AccountLayout from '../../layout/account';
import {
  QueryCurrentUser,
  QUERY_CURRENT_USER,
  updateCurrentUser,
  MutationSignIn,
  MUTATION_SIGN_IN,
} from '../../graphql/users';
import { QUERY_CART_BY_USER } from '../../util/Checkout';
import { findServiceError } from '../../util/apollo';

import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

interface State {
  fields: {
    email: string;
    password: string;
  };
  showPassword: boolean;
  errors: FieldError[];
  signInError: string;
}

class SignInState extends StateContainer {
  initialState = {
    fields: {
      email: '',
      password: '',
    },
    showPassword: false,
    errors: [],
    signInError: '',
  };

  shape = `
    {
      fields {
        email
        password
      }
      showPassword
      errors {
        valid
        message
        field
      }
      signInError
    }
  `;

  // Defining event handler to update the state
  updateField = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    if (field === 'email') {
      value = value.toLowerCase();
    }

    const fields = Object.assign({}, this.state.fields, { [field]: value});

    this.setState({ fields });
  };

  togglePassword = () => () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  setErrors = (errors: any) => {
    this.setState({ errors, signInError: '' });
  };

  setSignInError = (error: string) => {
    this.setState({ signInError: error });
  };
}

export default class SignInPage extends React.Component<RouteComponentProps> {
  signInUpdate = (cache: DataProxy, { data }: { data: UserMutationResults }) => {
    updateCurrentUser(cache, data.signIn);
  };

  completeSignIn = (redirectPath: string) => () => {
    this.props.history.push(redirectPath);
  };

  submit = (state: State, setErrors: (errors: FieldError[]) => void, signIn: MutationFn) => (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const validationResult = validateSignInParams(state.fields);
    if (validationResult.length > 0) {
      return setErrors(validationResult);
    }
    signIn({
      variables: _.pick(state.fields, 'password', 'email'),
    });
  };

  signInError = (setErrors: (errors: FieldError[]) => void, setSignInError: (error: string) => void) => (
    error: any
  ) => {
    const serviceError = findServiceError(error);
    if (serviceError && serviceError.extensions && serviceError.extensions.code === 'INVALID_CREDENTIALS') {
      return setErrors([{ valid: false, field: serviceError.data.data.field, message: serviceError.data.message }]);
    }
    setSignInError('Error encountered');
  };

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  render() {
    const { redirect } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const redirectPath = redirect || routes.app.base;
    return (
      <QueryCurrentUser query={QUERY_CURRENT_USER}>
        {({ data: userData }) => {
          if (userData!.currentUser!.role !== 'ANON') return <Redirect to={redirectPath} />;
          return (
            <Subscribe to={[SignInState]} namespace="SignIn">
              {({ state, updateField, togglePassword, setErrors, setSignInError }) => (
                <MutationSignIn
                  mutation={MUTATION_SIGN_IN}
                  onCompleted={this.completeSignIn(redirectPath)}
                  update={this.signInUpdate}
                  onError={this.signInError(setErrors, setSignInError)}
                  refetchQueries={[ { query: QUERY_CART_BY_USER } ]}
                >
                  {(signIn: MutationFn) => {
                    const emailError = _.find(state.errors, { field: 'email' });
                    const passwordError = _.find(state.errors, { field: 'password' });
                    return (
                      <AccountLayout>
                        <Helmet>
                          <title>Inkpop - Sign In</title>
                        </Helmet>
                        <div>
                          New to Inkpop? <Link to={routes.app.account.register}>Register</Link>
                          <div className="mt-2">
                            <h1 className="fs-xl color-coral fw-extra-bold">Sign In</h1>
                          </div>
                          <form className="mt-2 w-full" onSubmit={this.submit(state, setErrors, signIn)}>
                            <Input
                              label="Email"
                              className="w-full mt-1p5"
                              inputType="email"
                              value={state.fields.email}
                              onChange={updateField('email')}
                              error={emailError && !emailError.valid}
                              helper={emailError ? emailError.message : ''}
                              helperColor={emailError && !emailError.valid ? 'red' : 'green'}
                            />
                            <Input
                              label="Password"
                              className="w-full mt-1p5"
                              inputType={state.showPassword ? 'text' : 'password'}
                              value={state.fields.password}
                              onChange={updateField('password')}
                              onRightIconClick={togglePassword()}
                              rightIcon={state.showPassword ? 'eye' : 'eye-off'}
                              error={passwordError && !passwordError.valid}
                              helper={passwordError ? passwordError.message : state.signInError}
                              helperColor={
                                (passwordError && !passwordError.valid) || state.signInError ? 'red' : 'green'
                              }
                            />
                            <Link
                              className={classnames('fs-xs float-right mt-p25', {
                                '-mt-1p25': passwordError || state.signInError,
                              })}
                              to={routes.app.account.forgotPassword}
                            >
                              Forgot Password
                            </Link>
                            <Button buttonType="submit" className="w-full mt-1p5">
                              Sign In
                            </Button>
                          </form>
                        </div>
                      </AccountLayout>
                    );
                  }}
                </MutationSignIn>
              )}
            </Subscribe>
          );
        }}
      </QueryCurrentUser>
    );
  }
}
