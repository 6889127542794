import * as React from 'react';
import { Link } from 'react-router-dom'

import CallButton from 'inkp-components/dist/Components/CallButton';
import globalConfig from 'inkp-config/public';
import { routes } from 'inkp-routes/public';

interface Props {
  children: React.ReactNode;
}

export default class AccountLayout extends React.Component<Props> {
  render() {
    return (
      <div className="p-relative flex flex-col lg:flex-row h-cover" style={{ minHeight: '450px' }}>
        <div className="lg:p-absolute w-full">
          <div className="flex justify-between px-1 lg:px-6 py-p75 items-center">
            <Link to={routes.app.base}>
              <img
                src="https://inkp-production.32pt.com/public/logos/inkpop-wordmark.png"
                style={{ height: '32px' }}
              />
            </Link>
            <div>
              <div className="d-n lg:d-b">
                <CallButton phone={globalConfig.contact.phone} />
              </div>
              <div className="d-b lg:d-n">
                <a href={`tel:${globalConfig.contact.phone}`}>
                  <i className="mdi mdi-headset color-navy fs-icon-1p5" />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="d-n lg:d-b w-2/3 h-full bgc-navy color-white" style={{
          backgroundImage: `url("https://inkp-production.32pt.com/public/assets/high-five.jpg")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }} />
        <div className="w-full mt-1 lg:mt-0 lg:w-1/3 h-full flex lg:items-center justify-center">
          <style jsx={true}>{`
            .account-body {
              width: 100%;
              margin: 0 1rem;
            }

            @media only screen and (min-width: 1024px) {
              .account-body {
                width: 24rem;
              }
            }


          `}</style>
          <div className="account-body">{this.props.children}</div>
        </div>
      </div>
    );
  }
}
