function findUsableError(errors: any[]) {
  let err = errors.find((e) => e.extensions && e.extensions.downstreamErrors);
  if (err) {
    return err.extensions;
  }

  if (!err) {
    err = errors.find((e) => e.extensions && e.extensions.code);
  }

  return err;
}

export function findServiceError(error: any): any {

  let err;
  if (error.downstreamErrors && error.downstreamErrors[0]) {
    err = findServiceError(findUsableError(error.downstreamErrors));
    if (err) {
      return err;
    }
  }

  if (error.graphQLErrors && error.graphQLErrors[0]) {
    err = findServiceError(findUsableError(error.graphQLErrors));
    if (err) {
      return err;
    }
  }

  if (error.networkError && error.networkError.result && error.networkError.result.errors) {
    err = findServiceError(findUsableError(error.networkError.result.errors));
    if (err) {
      return err;
    }
  }

  if (error.errors) {
    err = findServiceError(findUsableError(error.errors));
    if (err) {
      return err;
    }
  }

  return error;

}
